// extracted by mini-css-extract-plugin
export var arrow = "contact-module--arrow--a4930";
export var button = "contact-module--button--67576";
export var card = "contact-module--card--b28e0";
export var col = "contact-module--col--299d1";
export var contactContainer = "contact-module--contactContainer--0b1cb";
export var contactData = "contact-module--contactData--d1115";
export var contactEntry = "contact-module--contactEntry--64f78";
export var form = "contact-module--form--fe4b3";
export var getInTouch = "contact-module--getInTouch--ece1f";
export var inlineCol = "contact-module--inline-col--02090";
export var inlineColFirst = "contact-module--inline-col-first--38b9d";
export var input = "contact-module--input--7acc0";
export var link = "contact-module--link--7e38f";
export var mainTitle = "contact-module--mainTitle--07d22";
export var pointerIcon = "contact-module--pointer-icon--fa26b";
export var radio = "contact-module--radio--8157b";
export var subTitle = "contact-module--subTitle--1c979";
export var text = "contact-module--text--7eda2";
export var uploadContainer = "contact-module--uploadContainer--b63c9";
export var uploadControl = "contact-module--uploadControl--52968";
export var wrapper = "contact-module--wrapper--860a0";